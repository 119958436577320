<template>
  <div class="p-4">
    <CardContainer
      class="gradient m-auto h-fit"
      :impose-appearance="'List'"
    >
      <form
        id="ProfilUpdateForm"
        class="p-0 w-fit d-flex flex-wrap"
        onsubmit="return false"
      >
        <div class="w-100 d-flex flex-wrap">
          <!-- Person -->
          <div class="inside-block w-100">
            <div class="bg-box">
              <div class="ms-3 fs-5 pt-2">
                {{ $tcust('ProfilUpdate.title.personType') }}
              </div>
              <div class="p-2">
                <div class="d-flex flex-row flex-wrap justify-content-around w-100 px-4">
                  <button
                    class="btn fs-6 m-2 px-5"
                    :class="Type === 0 ? 'btn-primary' : 'btn-outline-primary color-current'"
                    @click.prevent="Type = 0"
                  >
                    <span>{{ $tcust("Profil.personNatural") }}</span>
                  </button>
                  <button
                    class="btn fs-6 m-2 px-5"
                    :class="Type === 1 ? 'btn-primary' : 'btn-outline-primary color-current'"
                    @click.prevent="Type = 1"
                  >
                    <span>{{ $tcust("Profil.personLegal") }}</span>
                  </button>            
                </div>
              </div>
            </div>
          </div>

          <!-- Personals Infos -->
          <div
            class="inside-block flex-grow-1 w-50"
          >
            <div class="bg-box">
              <div class="ms-3 fs-5 pt-2">
                {{ $tcust('ProfilUpdate.title.user') }}
              </div>
              <div class="p-2">
                <!-- Natural -->
                <div v-if="Type === 0" class="p-3 pt-1">
                  <div class="d-flex custom-inside-size pb-3">
                    <div class="me-3">
                      {{ $tcust('ProfilUpdate.civility') }}
                    </div>
                    <div class="d-flex me-3">
                      <MDBRadio
                        value="0"
                        v-model="Civility"
                        name="Civility-0"
                      />
                      <div class="ProfilUpdate-text-align">
                        {{ $tcust('ProfilUpdate.civilityLabel.M') }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <MDBRadio
                        value="1"
                        v-model="Civility"
                        name="Civility-1"
                      />
                      <div class="ProfilUpdate-text-align">
                        {{ $tcust('ProfilUpdate.civilityLabel.Mme') }}
                      </div>
                    </div>
                  </div>
                  <MDBInput
                    id="LastName"
                    v-model="LastName"
                    type="text"
                    autocomplete="family-name"
                    required
                    :label="$tcust('Profil.name')"
                    class="text-center mb-3"
                  />
                  <MDBInput
                    id="FirstName"
                    v-model="FirstName"
                    type="text"
                    autocomplete="given-name"
                    required
                    :label="$tcust('Profil.firstName')"
                    class="text-center mb-3"
                  />
                  <MDBInput
                    id="UserName"
                    v-model="UserName"
                    type="email"
                    autocomplete="username"
                    required
                    :label="$tcust('Profil.mailAddress')"
                    class="text-center"
                  />                
                </div>

                <!-- Legal -->
                <div v-if="Type === 1" class="p-3">
                  <MDBInput
                    id="Name"
                    v-model="Name"
                    type="text"
                    autocomplete="organization"
                    required
                    :label="$tcust('Profil.name')"
                    class="text-center mb-3"
                  />
                  <MDBInput
                    id="UserName"
                    v-model="UserName"
                    type="email"
                    autocomplete="username"
                    required
                    :label="$tcust('Profil.mailAddress')"
                    class="text-center mb-3"
                  /> 
                  <div class="custom-inside-size mb-3">
                    <MDBSelect
                      id="IdentificationNumberType"
                      clearButton
                      :visibleOptions="7"
                      :label="$tcust('Profil.identificationNumberType')"
                      v-model:options="IdentificationNumberTypeList"
                      v-model:selected="IdentificationNumberType"
                      :preselect="false"
                      required
                    />
                  </div>
                  <MDBInput
                    id="IdentificationNumber"
                    v-model="IdentificationNumber"
                    type="text"
                    :label="$tcust('Profil.identificationNumber')"
                    class="text-center"
                    :disabled="IdentificationNumberType === ''"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Comms -->
          <div
            class="inside-block flex-grow-1 w-50"
          >
            <div class="bg-box">
              <div class="ms-3 fs-5 pt-2">
                {{ $tcust('ProfilUpdate.title.phones') }}
              </div>
              <div class="p-2">
                <div class="p-3">
                  <MDBInput
                    id="PhoneNumber"
                    v-model="PhoneNumber"
                    type="tel"
                    autocomplete="tel"
                    :label="$tcust('Profil.phoneNumber')"
                    class="text-center mb-3"
                  />
                  <MDBInput
                    id="CellPhoneNumber"
                    v-model="CellPhoneNumber"
                    type="tel"
                    :label="$tcust('Profil.cellPhoneNumber')"
                    class="text-center mb-3"
                  />
                  <MDBInput
                    id="FaxNumber"
                    v-model="FaxNumber"
                    type="tel"
                    :label="$tcust('Profil.faxNumber')"
                    class="text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Address -->
        <div
          v-if="!$store.state.app.modal"
          class="inside-block w-100"
        >
          <div class="bg-box pb-2">
            <div class="ms-3 fs-5 pt-2">
              {{ $tcust('ProfilUpdate.title.address') }}
            </div>
            <AddressInput
              class="p-2"
              @dataChange="Address = $event"
              :fill="fillAddress"
            />
          </div>
        </div>

        <!-- P.P. -->
        <div
          v-if="!$store.state.app.modal"
          class="inside-block w-100"
        >
          <div class="bg-box">
            <div class="ms-3 fs-5 pt-2">
              {{ $tcust('ProfilUpdate.title.image') }}
            </div>
            <div class="p-4">
              <MDBInput
                id="UserImage"
                v-model="UserImage"
                type="file"
                class="text-center"
                @change="ImageReader($event)"
              />
            </div>
            <div :class="Image ? 'm-auto w-fit mb-3 border border-primary rounded' : ''">
              <img
                id="Image"
                :src="Image"
              >            
            </div>
          </div>
        </div>

        <!-- Submit button -->
        <div class="inside-block w-100">
          <div class="bg-box">
            <div class="d-flex justify-content-center p-4">
              <MDBBtn
                color="primary"
                block
                class="w-fit px-5 fw-bold"
                type="submit"
                @click="verify($event)"
              >
                <span>{{ $tcust("common.form.validate") }}</span>
              </MDBBtn>
            </div>
          </div>
        </div>
      </form>
    </CardContainer>
  </div>
</template>

<script setup>
import { MDBInput, MDBBtn, MDBRadio, MDBSelect } from "mdb-vue-ui-kit";
import AddressInput from "@/components/form/AddressInput.vue";

import { computed, onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const Name = ref("");
const Address = ref("");
const Civility = ref("");
const UserName = ref("");
const LastName = ref("");
const FirstName = ref("");
const PhoneNumber = ref("");
const CellPhoneNumber = ref("");
const FaxNumber = ref("");
const Type = ref(0);
const IdentificationNumber = ref("");
const IdentificationNumberType = ref(null);
const IdentificationNumberTypeList = computed(() => {
  let list = [ ...store.state.companyIdentificationNumberTypeValues ];
  const selectList = [];
  for (let item of list) {
    let newObject = {};
    Object.assign(newObject, {"text": item.Label }, {"value": item.Value })
    selectList.push(newObject)
  }
  return selectList
});

const UserImage = ref("");
const Image = ref("");
const ImageReader = (event) => {
  let reader = new FileReader();
  reader.addEventListener("load", function(evt) {
    document.getElementById('Image').src = evt.target.result;
    Image.value = evt.target.result.toString().replace('data:image/png;base64,', '');
  }); 
  reader.readAsDataURL(event.target.files[0]);
};

const data = reactive({
  Name,
  Address,
  Civility,
  MailAddress: UserName,
  LastName,
  FirstName,
  PhoneNumber,
  CellPhoneNumber,
  FaxNumber,
  Type,
  Image,
  IdentificationNumber,
  IdentificationNumberType,
  Licenses: {
    LicenseKey: store.state.licenseChosen.LicenseKey,
  },
});

const fillAddress = ref(null);

onMounted(async () => {
  console.log(store.state.user.Person)
  if (route.name === "ProfilUpdate") {
    Name.value = store.state.user.Person.Name;
    fillAddress.value = store.state.user.Person.Address;
    Civility.value = store.state.user.Person.Civility;
    UserName.value = store.state.user.Person.MailAddress;
    LastName.value = store.state.user.Person.LastName;
    FirstName.value = store.state.user.Person.FirstName;
    PhoneNumber.value = store.state.user.Person.PhoneNumber;
    CellPhoneNumber.value = store.state.user.Person.CellPhoneNumber;
    FaxNumber.value = store.state.user.Person.FaxNumber;
    Type.value = store.state.user.Person.Type;
    IdentificationNumber.value = store.state.user.Person.IdentificationNumber;
    IdentificationNumberType.value = store.state.user.Person.IdentificationNumberType;
  }
  await store
  .dispatch("getCompanyIdentificationNumberTypeValues")
});

const verify = (event) => {
  if (!document.getElementById('ProfilUpdateForm').reportValidity()) return

  let selectType = document.querySelector("#IdentificationNumberType input");
  if (Type.value === 1 && !IdentificationNumberType.value && IdentificationNumberType.value !== 0) {
    event.preventDefault();
    return selectType.focus()
  }
  let selectCivility = document.querySelector("[name = Civility-0]");
  if (Type.value === 0 && !Civility.value && Civility.value !== 0) {
    event.preventDefault();
    return selectCivility.focus()
  }
  // for (let elem of document.querySelectorAll("#ProfilUpdateForm [required]")) {
  //   if (!elem.value) return elem.focus()
  // }
  // else document.getElementById('ProfilUpdateForm').requestSubmit();
  else submit();
};

const submit = async () => {
  await store
    .dispatch("personAddOrUpdate", data)
    .then(() => {
      if (route.name === "ProfilUpdate") {
        store.dispatch("getUser")
        .then(() => {
          router.push({
            name: "Profil",
          });
        })
      }
    })
};
</script>

<style lang="scss" scoped>
.gradient {
  max-width: 700px;
  & .inside-block:not(.w-100) {
    min-width: 260px;
  }
}
.custom-inside-size {
  width: 250px;
  max-width: 100% !important;
  margin: auto;
}
.ProfilUpdate-text-align {
  margin-top: -1px;
}

img {
  max-width: 250px;
  max-height: 250px;
}

@media (max-width: 768px) {
  .gradient {
    max-width: 400px;
  }
}
@media (max-width: 359.98px) {
  .p-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
</style>
