<template>
  <div class="d-flex flex-wrap">
    <div class="flex-grow-1 p-3 pt-0">
      <MDBInput
        id="Address1"
        v-model="Address1"
        type="text"
        autocomplete="address-line1"
        :label="$tcust('Profil.address')"
        class="mb-2"
        :required="props.required"
      />
      <MDBInput
        id="Address2"
        v-model="Address2"
        type="text"
        autocomplete="address-line2"
        :label="$tcust('Profil.address')"
        class="mb-2"
      />
      <MDBInput
        id="Address3"
        v-model="Address3"
        type="text"
        autocomplete="address-line3"
        :label="$tcust('Profil.address')"
        class="mb-2"
      />
      <MDBInput
        id="Address4"
        v-model="Address4"
        type="text"
        autocomplete="address-line4"
        :label="$tcust('Profil.address')"
      />                
    </div>
    <div class="flex-grow-1 mt-auto p-3">
      <div class="form-outline">
        <div class="w-50">
          <MDBInput
            id="ZipCode"
            v-model="ZipCode"
            type="text"
            autocomplete="postal-code"
            :label="$tcust('Profil.zipCode')"
            class="mb-2"
            :required="props.required"
          />
        </div>
      </div>
      <MDBInput
        id="City"
        v-model="City"
        type="text"
        autocomplete="address-level2"
        :label="$tcust('Profil.city')"
        class="mb-2"
        :required="props.required"
      />
      <MDBInput
        v-if="$tcust('Profil.state')"
        id="State"
        v-model="State"
        type="text"
        autocomplete="address-level1"
        :label="$tcust('Profil.state')"
        class="mb-2"
      />
      <MDBInput
        id="Country"
        v-model="Country"
        type="text"
        autocomplete="country-name"
        :label="$tcust('Profil.country')"
        :required="props.required"
      />                
    </div>
  </div>
</template>

<script setup>
import { MDBInput } from "mdb-vue-ui-kit";
import { computed, defineEmits, defineProps, reactive, onMounted, ref, watch } from "vue";


const props = defineProps({
  required: { 
    type: Boolean,
    default: false,
  },
  fill: {
    type: Object
  }
});
const fill = computed(() => {
  return props.fill
});

const emit = defineEmits(['dataChange']);

const Address1 = ref("");
const Address2 = ref("");
const Address3 = ref("");
const Address4 = ref("");
const ZipCode = ref("");
const City = ref("");
const State = ref("");
const Country = ref("");

const data = reactive({
  Address1,
  Address2,
  Address3,
  Address4,
  ZipCode,
  City,
  State,
  Country,
});

const fillData = () => {
  const updater = (key) => {
    if (fill.value) return fill.value[key]
    return null
  };
  Address1.value = updater("Address1");
  Address2.value = updater("Address2");
  Address3.value = updater("Address3");
  Address4.value = updater("Address4");
  ZipCode.value = updater("ZipCode");
  City.value = updater("City");
  State.value = updater("State");
  Country.value = updater("Country");
};

onMounted(() => {
  fillData();
});

watch(data, () => {
  emit('dataChange', data);
});
watch(fill, () => {
  fillData();
});
</script>
